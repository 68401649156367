import React from 'react';
import { 
    withStyles, Grid, Icon, 
    Button, Dialog, DialogTitle, DialogActions, DialogContent,
    AppBar, Toolbar, IconButton, TextField, InputAdornment,
    MenuItem
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axios from 'axios';
import moment from 'moment';
import Autocomplete from '../content/apps/bankRec/bankRecPanel/Autocomplete';
import ClientDialog from '../content/apps/clientList/ClientDialog';
import {
  snackBarComponent, platformHrThemeColor, getDynamicListOfQuarter, createQuarterDate, getCurrentQuarter, dateFormat_YYYYMMDD, disableHtmlElement
} from '../../genericFunctions';
import Formsy from 'formsy-react';
import { TextFieldFormsy, CheckboxFormsy, SelectFormsy } from '@fuse';
import '../../styles/quarter.css';

const useStyles = () => ({
    coName: { width: '257px', border:'1px solid lightgray', height: '50px', padding: '6px'},
    status: {
      width: '226px', border:'1px solid lightgray', height: '50px', padding: '6px'
    },
    tooltip: {width: '120px',backgroundColor: '#585858',color: '#ffffff',textAlign: 'center',borderRadius: '6px',
      padding: '5px 0px',zIndex: 1,float: 'right',position: 'absolute', left: '85%', fontSize: '12px', bottom: '10%'}
})

class AddTransaction extends React.Component {

    state = {
      coName: '',
      transactionDate: moment(new Date()).format("YYYY-MM-DD"),
      details: '', amount: 0.00, ledgerObjId: '', transType: '', taxType: '', objid: null,
      account: '', newAccntSel: '', manualCheck: false, taxCode: '', cleared: null,
      recType: '', checkNo: '',  ledgerId: 0, accountId: '', ledger: '', amtChk: false,
      taxTypeList: ['Federal','State','Local'], clientCoName: [], quarter: '', addTransDynamicQuartList:[],
      openDialogClients: false, chkAddClient: 'add', compId: '', compName: '', compFein: '', saveAddTransDisab: false,
      compStatus: 'Active', clientList: [], clientTransSnack: false, clientTransMessage: '', hover: false, taxConfirmation: ''
    }

    async componentDidMount() {
        await this.ledgerParseCode();
        await this.ledgerConfigDetails();
        await this.fetchBankRecTransactionClients();
        this.populateDetails();
        await this.tenantConfigurataion();
        const {dynamicQuart: addTransDynamicQuartList} = getDynamicListOfQuarter();
        this.setState({addTransDynamicQuartList});
    }

    populateDetails = () => {
      const {populate, undeterMineObj} = this.props
      if(populate) {
        const {clientCoName, clientList} = this.state;
        const {
          transactionDate, details, accountName, amount, co, ledger, ledgerId, accountId,
          transactionType: transType, checkNo, taxType, taxCode, taxConfirmation
        } = undeterMineObj;
        //const finalCo = co  && co.trim() ? clientCoName.filter(p=>p.split('-')[0].trim() === co.trim())[0] : '';
        const finalCo = co  && co.trim() ? clientList.find(p=> { return p.coId === co.trim()}) : '';
        this.setState({
          details, amtChk: true, amount: Number(amount.toFixed(2)),  coName: finalCo ? finalCo.coId: '', ledger, ledgerId, newAccntSel: accountName,
          transactionDate: moment(transactionDate).format('YYYY-MM-DD'), transType, checkNo: checkNo ? checkNo : '',
          accountId, taxType: taxType ? taxType : '', taxCode: taxCode ? taxCode : '', taxConfirmation: taxConfirmation ? taxConfirmation : ''
        }, ()=>{
          this.setState({amtChk: false});
        });
      }
    }

    tenantConfigurataion = async() => {
        let chkServiceq = 'Bank Rec';
        try {
            const verifyServiceadd = await axios.get(`/api/rec/items/agent/tenantConfig?service=${chkServiceq}`);
            if(verifyServiceadd.data && verifyServiceadd.data.status === 200) {
                const typeadd = verifyServiceadd.data.data; 
                this.setState(()=>({reconcilList: typeadd.length ? typeadd[0]['recType'] :[]}));
            }
        }
        catch(errsqa) {
            console.error(errsqa);
        }
      }

    ledgerParseCode = async () => {
        try {
            const ledgres = await axios.post(`/api/bankRec/items/agent/ledgerParseCode`,{
              select:{accounts: 1, transactionCodes: 1}
            });
            if(ledgres.data.data && ledgres.data.data.length) {
              const {transactionCodes, accounts: listacct} = ledgres.data.data[0];
              const transCodeList = transactionCodes ? transactionCodes.map(sca=>sca.transDesc): [];
              this.setState({transCodeList, listacct});
            }
        }
        catch(errs) {
            console.error(errs);
        }
    }

    ledgerConfigDetails = async () => {
        try {
        const ledresq = await axios.get('api/rec/tenant/ledgerConfig');
        if(ledresq.status === 200) {
            this.setState(()=>({configDetails: ledresq.data.data.configDetails}));
        }
        }
        catch(lederrq) {
            console.error(lederrq);
        }
     }

     fetchBankRecTransactionClients = async() => {
        const setCoNameQ = new Set();
        try {
            const resqclien = await axios.get('/api/bankRec/items/getCompanyName/client');
            if(resqclien && resqclien.status && resqclien.status === 200) {
              resqclien.data.data.forEach(vq=>{
                  vq.clients && vq.clients.forEach(s=>{ setCoNameQ.add(`${s.coId}${s.coName ? ` - ${s.coName}` : ''}`); })
              });
              this.setState({
                 clientCoName: Array.from(setCoNameQ),
                 clientList: resqclien.data.data[0].clients
              });
            }
        }
        catch(errq) {
          console.error(errq);
        }
    }

    handleClose = (close=false) => {
      const {closeLedger} = this.props;
        this.setState({ 
          details:'', amount:0.00, transType: '', taxType: '', quarter: '',
          transactionDate: moment(new Date()).format("YYYY-MM-DD"),
          coName:'', newAccntSel: '', manualCheck: false, recType: '', checkNo: '', ledger: '', amtChk: false
        }, ()=>{
          closeLedger(false);
        });
    }

    handleChangeManualAdd = evt => {
        this.setState({manualCheck: evt.target.checked});
    }

    handleChange = (evt, achk) => {
      let val = evt.target.value;
        if (achk && (achk !== 'transactionDate')) {
          if(achk === 'quarter' && val && val.toLowerCase() === 'current quarter') {
            const addSysCurrDate = dateFormat_YYYYMMDD();
            val = getCurrentQuarter(addSysCurrDate);
          }
          // 7228 - Add Transaction Amount Field (modified setState())
          this.setState({[achk]: achk === 'amount' ? Number(Number(evt.target.value).toFixed(2)) : val, amtChk: false},()=>{
            const {newAccntSel, configDetails, listacct: accountList} = this.state;
            if(achk === 'newAccntSel' && newAccntSel) {
                let accnInfo = configDetails.filter(cfg=>cfg.bankAccounts.find(an=>an.name === newAccntSel));
                if(accnInfo.length) {
                  let {accountId} = accnInfo[0]['bankAccounts'].find(j=>j.name === newAccntSel);
                  let ledgerId = accnInfo[0]['ledgers'][0]['ledgerId'];
                  this.setState({ledger: accountList.filter(g=>g.accountName === newAccntSel)[0]['ledger'], ledgerId, accountId});
                }
            } else {
              this.setState({amtChk: false});
            }
          });
        }
        if (achk && achk === "transactionDate") {
          let date = val; let startYear = 1900; let endYear = 2100;
          let currentYear = parseInt(date.substring(0, 4));
          if (currentYear >= startYear && currentYear <= endYear) {
              this.setState({transactionDate: val});
          }
        }
    }

    saveLedgerTransactions = async () => {
        const {
          details, amount, transactionDate, coName, ledger, noneAccountSel, taxType, taxCode, newAccntSel, ledgerId, accountId, 
          manualCheck, recType, checkNo, transType: transactionType, clientList, quarter, taxConfirmation
        } = this.state;
        const { createQuarter } = createQuarterDate(quarter);
        const {closeLedger} = this.props;
        const saveNewAddobj = clientList.find(s=> `${s.coId}${s.coName ? ` - ${s.coName}` : ''}` === coName);
        const saveNewAddobjTmp = clientList.find(s=> s.coId === coName)
        try {
          this.setState({saveAddTransDisab: true});
          await axios.post(`/api/bankRec/items/bankRecLedger/newBankLedger`, {
            data: { checkNo, details, amount, transactionDate, coName: saveNewAddobj ? saveNewAddobj.coId : saveNewAddobjTmp.coId, ledger, transactionType, addNew: true, taxType,
              account: newAccntSel ? newAccntSel : noneAccountSel, ledgerId, accountId, manualCheck, recType, taxCode, taxConfirmation, 
              autoMatch: true, quarter: createQuarter
            }
          });
          this.setState({saveAddTransDisab: false}); closeLedger(true);
          this.handleClose();
        }
        catch(err) {
          console.error(err); this.setState({saveAddTransDisab: false});
        }
      }

      onSelectChange = (chk, val) => {
        this.setState({[chk] : val});
      }

      handleClientClose = () => {
        this.setState({ 
            openDialogClients: false,
            chkAddClient: 'add',
            compName: '',
            compFein: '',
            compStatus: 'Active', compId: ''
        });
    }

    openCliDialog = (compId, openDialogClients) => {
      this.setState({compId, openDialogClients})
    }

    saveClientData = async (impchk, evt) => {
      const {compId, compFein, compName, compStatus} = this.state;
      let addTransCliObj={};
      if(impchk && impchk === 'add') {
        addTransCliObj = {
            coId: compId.trim().toUpperCase(),
            coName: compName.trim(),
            coFein: compFein.trim(),
            coStatus: compStatus, "edited" : false, "coEes" : "", "coType" : "Client", "coDba" : ""
        }
      }
      try {
          const transres = await axios.put('api/bankRec/items/addUpdateClient/client',{
            pushInfo: addTransCliObj
          });
          const transResponse = transres.data.data;
            if(typeof transResponse !== 'string' && Object.keys(transResponse).length) {
                await this.fetchBankRecTransactionClients();
            } else {
                this.setState({
                  clientTransSnack: !Array.isArray(transResponse) ? true: false,
                  clientTransMessage: !Array.isArray(transResponse) ? `${compId.trim()} already exist in the system` : '',
                });
            }
          const {clientCoName} = this.state;
          const finalCo = compId  && compId.trim() ? clientCoName.filter(p=>p.indexOf(compId.trim())!==-1)[0] : '';
          this.setState({coName: finalCo ? finalCo : ''});
          this.handleClientClose();
      }
      catch(err) {
          console.error(err);
      }
  }

  snackbarhandleTransClientClose = () => {
    this.setState({clientTransSnack: false});
  }

  handleMouseIn() {
    this.setState({ hover: true })
  }
  
  handleMouseOut() {
    this.setState({ hover: false })
  }

  // story 7228 - Add Transaction Amount Field
  onFocusAmountEmpty = (evt, key) => {
    let amountValue = this.state.amount;
    if (amountValue && parseFloat(amountValue) % 1 === 0) {
        amountValue = parseInt(amountValue, 10).toString(); // Remove .00
    }
    this.setState({ [key]: true, amount: amountValue });
  }

  onChangeAmountEmpty = (evt, chk) => {
    let value = evt.target.value;
    // Allow empty input
    if (value === "") {
        this.setState({ [chk]: value });
        return;
    }
    // Allow valid numeric input including negative values and decimals
    if (/^-?\d*\.?\d*$/.test(value)) {
        this.setState({ [chk]: value });
    }
  };
  // end

    render() {
        const {
          classes, openDialogLedger, populate
        } = this.props;
        const {
            coName, transactionDate, details, amount, manualCheck, transType, clientCoName, taxTypeList, openDialogClients,
            checkNo, newAccntSel, recType, amtChk, taxType, taxCode, reconcilList, transCodeList, listacct: accountList, saveAddTransDisab,
          chkAddClient, compName, compFein, compStatus, compId, clientTransSnack, clientTransMessage, hover, quarter, addTransDynamicQuartList, taxConfirmation
        } = this.state;
        const tooltipStyle = {
          display: hover ? 'block' : 'none'
        }
        const amtobj = amtChk && amount === 0 ? { value: '' } : amtChk && amount !== 0 ? { value: amount } : { value: Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) };// 7228 - Add Transaction Amount Field (modified amtobj)
        let enableSave = false;
        if(chkAddClient === 'add' && compId.trim() && compName.trim()) {
          if(compStatus) {
              enableSave = true;
          }
        }
        const disableSaveAdd = saveAddTransDisab ? {...disableHtmlElement} : {};
        return (
          <>
            <Dialog
          open={openDialogLedger} onClose={this.handleClose}
          disableBackdropClick disableEscapeKeyDown fullWidth
          maxWidth={'md'}
         >
        <DialogTitle component="div" className="p-0">
        <AppBar style={{ color: 'white', backgroundColor:  platformHrThemeColor }} position="static" elevation={1}>
                    <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16" style={{ overflowX: "scroll", overflowY: "hidden" }}>
                    <div className="flex flex-1"> {'Add Ledger Transaction'}
                    </div>
                    <IconButton color="inherit" ><Icon onClick={this.handleClose}>close</Icon></IconButton>
                    </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className="p-16 sm:p-24">
          <div className="mb-16 sm:mb-8 flex">
          <Formsy
            ref={(form) => this.form = form}
            className="flex flex-col justify-center w-full"
          >
          <Grid container spacing={3}>
          {amount === false && <Grid item xs={12} style={{ color: 'red', textAlign: 'center', marginBottom: '14px' }}>Only two digits after decimal Point!</Grid>}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                clientCoName={clientCoName} secondFlag={true}
                onSelectChange={this.onSelectChange}
                openCliDialog={this.openCliDialog}
                coName={coName} populateco={populate}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingLeft: '28px', marginBottom:'25px'}}>
              <TextFieldFormsy
                id="date" label="Transaction Date *" type="date" value={moment(transactionDate).format('YYYY-MM-DD')} name="date" fullWidth
                variant="outlined" onChange={evt => this.handleChange(evt, 'transactionDate')} InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ position:'relative', bottom:'6px' }}>
              <TextFieldFormsy
                margin="dense" id="chequeNo" label={<span style={{position:'relative', bottom: checkNo ? '0px' : '2px'}}>Check No</span>} 
                value={checkNo} type="text" variant="outlined" onChange={evt => this.handleChange(evt, 'checkNo')}
                name="Check No" fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingLeft: '28px', position:'relative', bottom:'13px', marginBottom:'18px'}}>
              <TextField
                margin="dense" id="name" label="Amount *" variant="outlined"
                // story 7228 - Add Transaction Amount Field (modified)
                type={amtChk ? "number": "text"}
                onBlur={evt => this.handleChange(evt, 'amount')}
                defaultValue={amount === 0 || amount ? Number(amount).toFixed(2) : ''}
                onChange={evt=>this.onChangeAmountEmpty(evt, 'amount')}
                onFocus={evt=>this.onFocusAmountEmpty(evt, 'amtChk')}
                {...amtobj}
                // end
                InputProps={{
                  style: {
                    height: '55px'
                  },
                  startAdornment: (<InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>),
                }}
                fullWidth
              />
            </Grid>
              <Grid item xs={12} sm={6} style={{ position:'relative', bottom:'15px' }}>
                  <TextField
                    name='transType' select
                    value={transType ? transType : ''} label={<span style={{position:'relative', bottom: transType ? '0px' : '2px'}}>Trans Type</span>} 
                    onChange={evt => this.handleChange(evt, 'transType')}  variant="outlined" fullWidth
                  >
                    <MenuItem value={""}>Select Trans Type</MenuItem>
                    {
                      transCodeList && transCodeList.length > 0 && transCodeList
                        .sort((ta, tb) => (ta.toLowerCase() > tb.toLowerCase()) - (ta.toLowerCase() < tb.toLowerCase()))
                        .map(trans =>
                          <MenuItem value={trans}>{trans}</MenuItem>
                        )
                    }
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} style={{ position:'relative', bottom:'15px', paddingLeft: '29px'}}>
                  <TextField
                    value={newAccntSel ? newAccntSel : ''} onChange={evt => this.handleChange(evt, 'newAccntSel')} name="account"
                    variant="outlined" label={<span style={{position:'relative', bottom: newAccntSel ? '0px' : '2px'}}>Account *</span>} fullWidth select
                  >
                    <MenuItem value={""}>Select Account *</MenuItem>
                    {
                      accountList && accountList.length > 0 && accountList.map(v => v.accountName)
                        .sort((a, b) => (a.toLowerCase() > b.toLowerCase()) - (a.toLowerCase() < b.toLowerCase()))
                        .map(acct => <MenuItem value={acct}>{acct}</MenuItem>)
                    }
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} style={{ position:'relative', top:'5px' }}>
                  <TextFieldFormsy
                    margin="dense" id="taxCode" label={<span style={{position:'relative', bottom: taxCode ? '0px' : '2px'}}>Tax Code</span>} name="taxCode"
                    type="text" variant="outlined" onChange={evt => this.handleChange(evt, 'taxCode')} value={taxCode}
                    fullWidth
                    multiline
                    rows={1}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingLeft: '30px', position:'relative', top:'5px' }}>
                  <TextField
                    value={taxType ? taxType : ''} onChange={evt => this.handleChange(evt, 'taxType')} name="taxType"
                    variant="outlined" label={<span style={{position:'relative', bottom: taxType ? '0px' : '2px'}}>Tax Type</span>} fullWidth select
                  >
                    <MenuItem value={""}>Select Tax Type</MenuItem>
                    {
                      taxTypeList && taxTypeList.length > 0 && taxTypeList
                        .sort((tat, tbt) => (tat.toLowerCase() > tbt.toLowerCase()) - (tat.toLowerCase() < tbt.toLowerCase()))
                        .map(taxts =>
                          <MenuItem value={taxts}>{taxts}</MenuItem>
                        )
                    }
                  </TextField>
                </Grid>
                {/* #5098 - tax confirmation field */}
                <Grid item xs={12} sm={6} style={{ position:'relative', top:'22px', marginTop:'4px' }}>
                  <TextFieldFormsy
                    margin="dense" id="taxConfirmation" label={<span style={{position:'relative', bottom: taxConfirmation ? '0px' : '2px'}}>Tax Confirmation</span>} name="Tax Conf"
                    type="text" variant="outlined" onChange={evt => this.handleChange(evt, 'taxConfirmation')}
                    value={taxConfirmation}
                    fullWidth
                    multiline
                    rows={1}
                  />
                </Grid>
                {/* #END */}                
                <Grid item sm={6} xs={12}  style={{ paddingLeft: '30px', position:'relative', top:'22px', marginTop:'4px'}}>
                  <TextField
                    name='bnkRecAddQuarterTypes' value={quarter}
                    onChange={evt => this.handleChange(evt, 'quarter')} 
                    variant="outlined" label={<span style={{position:'relative', bottom: quarter ? '0px' : '4px'}}>Quarter</span>} fullWidth select
                  >
                    <MenuItem value={""}>Select Quarter</MenuItem>
                    {addTransDynamicQuartList.map(addgquarte => <MenuItem value={addgquarte} style={{ color: '#000000' }}>{addgquarte}</MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} style={{ marginTop: '42px' }}>
                  <TextFieldFormsy
                    margin="dense" id="name" label="Details *" name="Details"
                    type="text" variant="outlined" onChange={evt => this.handleChange(evt, 'details')} value={details}
                    multiline
                    rows={3}
                    fullWidth
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} style={{ whiteSpace: 'nowrap', position: 'relative', top: '9px' }}>
                    <CheckboxFormsy
                      checked={manualCheck} value={manualCheck} onChange={event => this.handleChangeManualAdd(event)} color="secondary" name="manualCheck"
                      inputProps={{ 'aria-label': 'secondary checkbox' }} label="Reconciling Item?"
                    />
                  </Grid>
                  {manualCheck && <Grid item xs={12} sm={6} style={{ paddingTop: '16px', paddingLeft:'28px'}}>
                    <SelectFormsy
                      value={recType ? recType : ''} onChange={evt => this.handleChange(evt, 'recType')}
                      displayEmpty style={{ position: 'relative', left: '32px' }} variant="outlined" name="recType"
                    >
                      <MenuItem value={""}>Select RecType</MenuItem>
                      {
                        reconcilList && reconcilList.length > 0 && reconcilList.filter(na => na !== 'Aged Uncleared' && na !== 'Negative Liabilities')
                          .sort((a, b) => (a.toLowerCase() > b.toLowerCase()) - (a.toLowerCase() < b.toLowerCase()))
                          .map(recs => <MenuItem value={recs}>{recs}</MenuItem>)
                      }
                    </SelectFormsy>
                  </Grid>}
                </Grid>
              </Grid>
              {clientTransSnack &&
                snackBarComponent({
                  clientSnack: clientTransSnack, duration: 4000, anchorigin: { vertical: 'bottom', horizontal: 'right' }, snackmessage: clientTransMessage,
                  snackbarhandleClose: this.snackbarhandleTransClientClose
                })
              }
            </Formsy>
          </div>
        </DialogContent>
        {amount && details && transactionDate && coName.trim() && newAccntSel ? '' : <div style={{position: 'relative'}}>
          <div className={classes.tooltip} style={tooltipStyle}>{amount && details && transactionDate && coName.trim() && newAccntSel ? '' : 'Please fill out the required (*) fields'} </div>
        </div>}
        <DialogActions>
          <Button 
            autoFocus  onClick={this.handleClose}  style={{color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize'}}
            > Cancel </Button>
          <Button 
            // title={amount && details && transactionDate && coName.trim() && newAccntSel ? '' : 'Please fill out the required (*) fields'}
            onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)}
            onClick={this.saveLedgerTransactions} 
            style={amount && details && transactionDate && coName.trim() && newAccntSel ? {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize', ...disableSaveAdd}:
            {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize', pointerEvents:'auto', opacity:0.5}}
            disabled={amount && details && transactionDate && coName.trim() && newAccntSel ? false : true}
            >
            {'Save'}
          </Button>
        </DialogActions>
        </Dialog>
        {
              openDialogClients && 
              <ClientDialog 
                  openDialogClients={openDialogClients}
                  handleClose = {this.handleClientClose}
                  handleChange = {this.handleChange}
                  saveClientData = {this.saveClientData}
                  compId={compId} compName={compName} compFein={compFein}
                  compStatus={compStatus} chkAddClient={chkAddClient} enableSave={enableSave}
                  classes={classes}
              />
          }
        </>
        )
    }
}

export default withStyles(useStyles)(AddTransaction);