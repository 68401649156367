import React from 'react';
import { 
    withStyles, Grid, Icon, Button, Dialog, DialogTitle, DialogActions, DialogContent, Tooltip,
    AppBar, Toolbar, IconButton, TextField, InputAdornment, Radio, MenuItem
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axios from 'axios'; import Autocomplete from '../content/apps/bankRec/bankRecPanel/Autocomplete';
import moment from 'moment';
import TransferMoneyClientDialog from '../content/apps/clientList/ClientDialog';
import {platformHrThemeColor, disableHtmlElement, snackBarComponent} from '../../genericFunctions'; 
import CryptoJSTransfer from "crypto-js";

const useStyles = () => ({
    coName: {
        width: '244px', border:'1px solid lightgray', height: '50px', padding: '6px'
    },
    status: {
      border:'1px solid lightgray', height: '50px', padding: '6px', width: '226px'
    },
    paperFullWidth: {overflowY: 'visible'}
})

class TransferMoney extends React.Component {

    state = {
      coName: '',
      transactionDate: moment(new Date()).format("YYYY-MM-DD"), bnkDetails: '',
       amount: 0.00, objid: null, openDialogTransfer: false, fromAmt: 0.00,
      account: '', fromAccntSel: '', toAccntSel: '', cleared: null, clientCoName: [], listacct: [],
      recType: '',  samtChk: false, transCodeList: [],
      bnkTransfer: 'bnkTransfer', nonBnkTransfer: null, bankingTransferTo: [{accountName: '', co: '', amount: 0.00}],
      openDialogTransferClients: false, chkAddTransferClient: 'add', compTransferId: '', compTransferName: '', compTransferFein: '',
      compTransferStatus: 'Active', clientTransferList: [], fromCo: '', nonBankingTransferTo : [{co: '', amount: 0.00}],
      fromNonBankCo: '', fromAccntNonBnking: '', fromNonBnkAmt: 0, removeAddAmt: false, bankFromAmtChk: false, bankToAmtChk: false, nonbankFromAmtChk: false, nonbankToAmtChk: false,
      clientTransfSnack: false, clientTransfSnckmessage: '', frmNonBnkTaxCode: '', frmBnkTaxCode: '', disableTransfer: false
    }

    async componentDidMount() {
        await this.ledgerParseCode();
        await this.fetchTransferBankRecClients();
        await this.ledgerConfigDetails();
    }

    ledgerConfigDetails = async () => {
      try {
      const ledresq = await axios.get('api/rec/tenant/ledgerConfig');
      if(ledresq.status === 200) {
          this.setState(()=>({configDetails: ledresq.data.data.configDetails}));
      }
      }
      catch(lederrq) {
          console.error(lederrq);
      }
   }

    ledgerParseCode = async () => {
      try {
          const res = await axios.post(`/api/bankRec/items/agent/ledgerParseCode`,{
            select:{accounts: 1, transactionCodes: 1}
          });
          if(res.data.data && res.data.data.length) {
            const {transactionCodes, accounts: listacct} = res.data.data[0];
            let transCodeList = transactionCodes ? transactionCodes: [];
            this.setState({transCodeList, listacct});
          }
      }
      catch(err) {
          console.error(err);
      }
  }

  fetchTransferBankRecClients = async() => {
    const setCoNameQ = new Set();
    try {
        const resq = await axios.get('/api/bankRec/items/getCompanyName/client');
        if(resq && resq.status && resq.status === 200) {
          resq.data.data.forEach(vq=>{
              vq.clients && vq.clients.forEach(s=>{ setCoNameQ.add(`${s.coId}${s.coName ? ` - ${s.coName}` : ''}`); })
          });
          this.setState({
            clientCoName: Array.from(setCoNameQ),
            clientTransferList: resq.data.data[0].clients
          });
        }
    }
    catch(errq) {
      console.error(errq);
    }
}

    handleClose = (close=false) => {
      const {closeTransferMoney} = this.props;
        this.setState({ 
          amount:0.00, openDialogTransfer: false,
          transactionDate: moment(new Date()).format("YYYY-MM-DD"),
          coName:'', fromAccntSel: '', toAccntSel: ''
        }, ()=>{
          closeTransferMoney();
        });
    }

    handleChangeManualAdd = evt => {
        this.setState({manualCheck: evt.target.checked});
    }

    mapKeyNames = key => {
      let coKey = key;
      switch(key) {
        case 'compId' :  coKey = 'compTransferId'; break;
        case 'compName' :  coKey = 'compTransferName'; break;
        case 'compFein' :  coKey = 'compTransferFein'; break;
        case 'compStatus' :  coKey = 'compTransferStatus'; break;
      }
      return coKey;
    }

    handleChange = (evt, keyFrom) => {
        const val = evt.target.value;
        const chk = this.mapKeyNames(keyFrom);
        if (chk && (chk !== 'transactionDate')) {
          this.setState({[chk]: chk === 'fromAmt' || chk === 'fromNonBnkAmt' ? Number(Number(val).toFixed(2)) : val, removeAddAmt: true, bankFromAmtChk: true, bankToAmtChk: true, nonbankFromAmtChk: true, nonbankToAmtChk: true},()=>{
            this.setState({removeAddAmt: false, bankFromAmtChk: false, bankToAmtChk: false, nonbankFromAmtChk: false, nonbankToAmtChk: false});
            if(chk === 'fromAccntNonBnking') {
              const {fromAccntNonBnking, listacct, nonBankingTransferTo} = this.state;
              const resetTaxCode = listacct.some(n=>n.accountName.trim() === fromAccntNonBnking.trim() && n.ledger !== 'Tax');
              if(resetTaxCode) {
                this.setState({
                  nonBankingTransferTo: nonBankingTransferTo.map(s=>({...s, taxCode: ''})),
                  frmNonBnkTaxCode: ''
                })
              }
            }
            if(chk === 'fromAccntSel') {
              const {listacct, fromAccntSel} = this.state;
              const resetBnkTaxCode = listacct.some(n=>n.accountName.trim() === fromAccntSel.trim() && n.ledger !== 'Tax');
              if(resetBnkTaxCode) {
                this.setState({frmBnkTaxCode: ''});
              }
            }
          });
        }
        if (chk && chk === "transactionDate") {
          let date = val, startYear = 1900, endYear = 2100;
          let currentYear = parseInt(date.substring(0, 4));
          if (currentYear >= startYear && currentYear <= endYear) {
              let momentdate = moment(val).toISOString(true);
              this.setState({transactionDate: momentdate});
          }
        }
    }

    genTransType = () => {
      const {transCodeList} = this.state;
      let fromTransType = '', toTransType = '';
      const fromObj = transCodeList.find(g=>g.transCode === '506');
      const toObj = transCodeList.find(g=>g.transCode === '206');
      if(fromObj) {
        fromTransType = fromObj.transDesc;
      }
      if(toObj) {
        toTransType = toObj.transDesc;
      }
      return {fromTransType, toTransType};
    }

    payLoadObj = () => {
      const {
        bankingTransferTo, bnkTransfer, fromAccntSel, fromAmt, fromCo, bnkDetails, transactionDate, fromAccntNonBnking, fromNonBnkAmt,
        fromNonBankCo, nonBankingTransferTo, frmNonBnkTaxCode, frmBnkTaxCode, clientTransferList
      } = this.state;
      let hash = CryptoJSTransfer.algo.SHA256.create(); // Initialize hash with an empty string
      let tmpFrmBnkCo = clientTransferList.find(s=> `${s.coId}${s.coName ? ` - ${s.coName}` : ''}` === fromCo);
      if(tmpFrmBnkCo)
        tmpFrmBnkCo = tmpFrmBnkCo ? tmpFrmBnkCo.coId : '';

      let tmpFrmNonBnkCo = clientTransferList.find(s=> `${s.coId}${s.coName ? ` - ${s.coName}` : ''}` === fromNonBankCo);
      if(tmpFrmNonBnkCo)
        tmpFrmNonBnkCo = tmpFrmNonBnkCo ? tmpFrmNonBnkCo.coId: '';

      let transDate = moment(transactionDate).toISOString(true);
      let fromPayLoadObj = {}, finalPayLoad = [];
      const {fromTransType, toTransType} = this.genTransType();
      const {accountId, ledger, ledgerId} = this.genAccntLedgerInfo(bnkTransfer ? fromAccntSel: fromAccntNonBnking);
      if(bnkTransfer) {
        fromPayLoadObj = [{
          accountName: fromAccntSel, amount: -Math.abs(fromAmt), co: tmpFrmBnkCo, details: bnkDetails, transactionDate: transDate,
          accountId, ledger, ledgerId, transactionType: fromTransType, taxCode: frmBnkTaxCode
        }];
        bankingTransferTo.forEach(py=>{
          py.amount = Math.abs(py.amount);  py.transactionType = toTransType;
          py.details = bnkDetails; py.transactionDate = transDate;
        });
        finalPayLoad = [...finalPayLoad, ...bankingTransferTo, ...fromPayLoadObj];
        finalPayLoad.forEach(btr=> {const chunkBtrString = JSON.stringify(btr); hash.update(CryptoJSTransfer.enc.Utf8.parse(chunkBtrString));});
      } else {
        fromPayLoadObj = [{
          accountName: fromAccntNonBnking, amount: -Math.abs(fromNonBnkAmt), co: tmpFrmNonBnkCo, details: bnkDetails, transactionDate: transDate,
          accountId, ledger, ledgerId, transactionType: fromTransType, nonbanking: true, taxCode: frmNonBnkTaxCode
        }];
        nonBankingTransferTo.forEach(py=>{
          py.amount = Math.abs(py.amount);  py.transactionType = toTransType;
          py.details = bnkDetails; py.transactionDate = transDate; py.accountName = fromAccntNonBnking;
          py.accountId = accountId;  py.ledger = ledger; py.ledgerId = ledgerId; py.nonbanking = true;
        });
        finalPayLoad = [...finalPayLoad, ...nonBankingTransferTo, ...fromPayLoadObj];
        finalPayLoad.forEach(btr=> {const chunkNnBtrString = JSON.stringify(btr); hash.update(CryptoJSTransfer.enc.Utf8.parse(chunkNnBtrString));})
      }
      const hashedTData = hash.finalize().toString();
      return {finalPayLoad, hashedTData};
    }

    transferLedgerTransactions = async () => {
      const {nonBnkTransfer} = this.state;
        try {
          this.setState({disableTransfer: true});
          const {finalPayLoad, hashedTData: transferHash} = this.payLoadObj();
          await axios.post(`/api/bankRec/items/bankRecLedger/transferMoney`, {
            data: {
              payload: finalPayLoad, transferHash, nonBnkTransfer
            }
          });
          
          this.handleClose(true); this.setState({disableTransfer: false});
        }
        catch(errTransfer) {
          console.error(errTransfer); this.setState({disableTransfer: false});
        }
    }

    handleBankingTransfer = (evt, chk) => {
      const nullifyVal = chk === 'bnkTransfer' ? {
        bankingTransferTo: [{accountName: '', co: '', amount: 0.00}],
        fromCo: '', fromAccntSel: '', fromAmt: 0.00, bnkDetails: '', removeAddAmt: false, frmBnkTaxCode:'', bankFromAmtChk: false, bankToAmtChk: false
      } : {
        fromNonBnkAmt: 0, fromNonBankCo: '', removeAddAmt: false, nonbankFromAmtChk: false, nonbankToAmtChk: false,
        nonBankingTransferTo : [{co: '', amount: 0.00}], fromAccntNonBnking: '', bnkDetails: '', frmNonBnkTaxCode: ''
      };
      this.setState({
        bnkTransfer: chk === 'bnkTransfer' ? chk : null,
        nonBnkTransfer : chk === 'nonBnkTransfer' ? chk : null,
        ...nullifyVal
      });
    }

    addLedgerItem = () => {
        const {bankingTransferTo, bnkTransfer, nonBankingTransferTo} = this.state;
        if(bnkTransfer) {
          bankingTransferTo.push({accountName: '', co: '', amount: 0.00});
          this.setState({bankingTransferTo, removeAddAmt: false, bankFromAmtChk: false, bankToAmtChk: false});
        } else {
          nonBankingTransferTo.push({co: '', amount: 0.00});
          this.setState({nonBankingTransferTo, removeAddAmt: false, nonbankFromAmtChk: false, nonbankToAmtChk: false});
        }
    }

    deleteLedgerItem = (evt, delIndex) => {
      const {bankingTransferTo, bnkTransfer, nonBankingTransferTo} = this.state;
      if(bnkTransfer) {
        bankingTransferTo.splice(delIndex, 1);
        this.setState({bankingTransferTo, removeAddAmt: true, bankFromAmtChk: false, bankToAmtChk: false});
      } else {
        nonBankingTransferTo.splice(delIndex, 1);
        this.setState({nonBankingTransferTo, removeAddAmt: true, nonbankFromAmtChk: false, nonbankToAmtChk: false});
      }
    }

    onToCoSelect = (chk, val, obj) => {
      const {bankingTransferTo, bnkTransfer, nonBankingTransferTo, clientTransferList} = this.state;
      const {toInd} = obj || {};
      const onToCoObj = clientTransferList.find(s=> `${s.coId}${s.coName ? ` - ${s.coName}` : ''}` === val);
      if(bnkTransfer && typeof toInd === 'number') {
        bankingTransferTo[toInd]['co'] = onToCoObj ? onToCoObj.coId : '';
        this.setState({bankingTransferTo});
      } else {
        nonBankingTransferTo[toInd]['co'] = onToCoObj ? onToCoObj.coId : '';
        this.setState({nonBankingTransferTo});
      }
    }

    onFromBnkTransferCoSelect = (fmChk, val) => {
      const {bnkTransfer, bankingTransferTo, clientTransferList} = this.state;
      const frmBnkObj = clientTransferList.find(s=> `${s.coId}${s.coName ? ` - ${s.coName}` : ''}` === val);
      if(bnkTransfer) {
        bankingTransferTo[0]['co'] = frmBnkObj ? frmBnkObj.coId : '';
        this.setState({fromCo : val, bankingTransferTo});
      } else {
        this.setState({fromNonBankCo : val});
      }
    }

    handleTransferClientClose = () => {
      this.setState({ 
          openDialogTransferClients: false,
          chkAddTransferClient: 'add',
          compTransferName: '',
          compTransferFein: '',
          compTransferStatus: 'Active', compTransferId: '',
          bankFromAmtChk: false, bankToAmtChk: false, nonbankFromAmtChk: false, nonbankToAmtChk: false
      });
  }

  saveClientTransferData = async (impchk, evt) => {
    const {compTransferId, compTransferFein, compTransferName, compTransferStatus} = this.state;
    let transfObj = {};
    if(impchk && impchk === 'add') {
        transfObj = {
          coId: compTransferId.trim().toUpperCase(),
          coName: compTransferName.trim(),
          coFein: compTransferFein.trim(),
          coStatus: compTransferStatus, "edited" : false, "coEes" : "", "coType" : "Client", "coDba" : ""
      }
    }
    try {
        const transfRes = await axios.put('api/bankRec/items/addUpdateClient/client',{ pushInfo : transfObj }); 
        const updTransfResponse = transfRes.data.data;
        if(typeof updTransfResponse !== 'string' && Object.keys(updTransfResponse).length) {
            await this.fetchTransferBankRecClients();
        } else {
            this.setState({
                clientTransfSnack: !Array.isArray(updTransfResponse) ? true: false,
                clientTransfSnckmessage: !Array.isArray(updTransfResponse) ? `${compTransferId.trim()} already exist in the system` : '',
            });
        }
        const {clientCoName} = this.state;
        const finalCo = compTransferId  && compTransferId.trim() ? clientCoName.filter(p=>p.indexOf(compTransferId.trim())!==-1)[0] : '';
        this.setState({compTransferName: finalCo ? finalCo : ''});
        this.handleTransferClientClose();
    }
    catch(errSaveTransferClient) {
        console.error(errSaveTransferClient);
    }
  }

  openCliTransferDialog = (compTransferId, openDialogTransferClients) => {
    this.setState({compTransferId, openDialogTransferClients});
  }

  genAccntLedgerInfo = actBnkTransferVal => {
    const {configDetails, listacct} = this.state;
    let accnInfo = configDetails.filter(cfg=>cfg.bankAccounts.find(an=>an.name === actBnkTransferVal));
    const {accountId} = accnInfo[0]['bankAccounts'].find(j=>j.name === actBnkTransferVal);
    let ledgerId = accnInfo[0]['ledgers'][0]['ledgerId'];
    let ledger = listacct.filter(g=>g.accountName === actBnkTransferVal)[0]['ledger'];
    return {ledger, ledgerId, accountId};
  }

  onBnkTransferToAccount = (evt, ind, key) => {
    const {bankingTransferTo, bnkTransfer, nonBankingTransferTo} = this.state; let actBnkTransferVal = evt.target.value.trim();
    actBnkTransferVal = key === 'amount' ? Number(Number(actBnkTransferVal).toFixed(2)) : actBnkTransferVal;
    if(bnkTransfer) {
      bankingTransferTo[ind][key] = actBnkTransferVal;
      if(key !== 'amount' && key !== 'taxCode' && actBnkTransferVal) {
        const {ledger, ledgerId, accountId} = this.genAccntLedgerInfo(actBnkTransferVal);
        if(ledger !== 'Tax') {
          bankingTransferTo[ind]['taxCode'] = '';
        }
        bankingTransferTo[ind]['ledger'] = ledger; bankingTransferTo[ind]['accountId'] = accountId;
        bankingTransferTo[ind]['ledgerId'] = ledgerId; 
      } 
      this.setState({bankingTransferTo, removeAddAmt: true, bankFromAmtChk: true, bankToAmtChk: true, nonbankFromAmtChk: true, nonbankToAmtChk: true}, ()=>
      {this.setState({removeAddAmt: false, bankFromAmtChk: false, bankToAmtChk: false, nonbankFromAmtChk: false, nonbankToAmtChk: false});
      });
    } else {
      nonBankingTransferTo[ind][key] = actBnkTransferVal; 
      this.setState({nonBankingTransferTo, removeAddAmt: true}, ()=>
      {this.setState({removeAddAmt: false, bankFromAmtChk: false, bankToAmtChk: false, nonbankFromAmtChk: false, nonbankToAmtChk: false});});
    }
  }

  bankingTransferJSX = () => {
    const {
      fromAccntSel, clientCoName, listacct, bankingTransferTo, fromAmt, fromCo, bankFromAmtChk, bankToAmtChk, frmBnkTaxCode
  } = this.state;
  const frmBnkAmtVal = bankFromAmtChk && fromAmt === 0 ? { value: '' } : bankFromAmtChk && fromAmt !== 0 ? { value: fromAmt } : { value: Number(fromAmt).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) };// 7228 - Add Transaction Amount Field (modified amtobj)
  const checkTaxBnkTransfAccnt = listacct.some(n=>n.accountName.trim() === fromAccntSel.trim() && n.ledger === 'Tax');
  const fromCoClass = {labelStyle:{position:'relative', bottom: fromCo ? '0px': '6px'},inputStyle: {height:'5rem'}};
    return(
      <>
          <Grid container spacing={3}>
              <Grid item xs={3}>
                  <TextField
                          value={fromAccntSel ? fromAccntSel : ''} onChange={evt =>this.handleChange(evt,'fromAccntSel')} select
                          variant="outlined" label={<span style={{position:'relative', bottom: fromAccntSel ? '0px' : '7px'}}>From Account</span>}
                          style={{marginTop:'10px', width:'65%'}}
                          name='fromAccount' InputProps={{ style: {height:'4.8rem' } }}
                  >
                          <MenuItem value={""}>From Account</MenuItem>
                          {
                          listacct && listacct.length > 0 && listacct.map(v=>v.accountName)
                          .sort((a,b) => (a.toLowerCase() > b.toLowerCase()) - (a.toLowerCase() < b.toLowerCase()))
                          .map(acct =>
                              <MenuItem value={acct}>{acct}</MenuItem>
                          )
                      }
                  </TextField>
                </Grid>
                <Grid xs={1} item></Grid>
                <Grid item xs={4} style={{position:'relative', top:'10px'}}>
                  <Autocomplete
                      clientCoName={clientCoName} money={true} placeHoldCo="From Customer"
                      onSelectChange={this.onFromBnkTransferCoSelect}
                      openCliDialog = {this.openCliTransferDialog}
                      coName={fromCo} bankFromCo={true} autoCompleteClass={fromCoClass}
                    />
                </Grid>
                <Grid item xs={2} style={{position:'relative', left:'14px', top:'3px'}}>
                  <TextField
                        margin="dense" id="name" label="Amount"
                        variant="outlined" onBlur={evt =>this.handleChange(evt, 'fromAmt')}
                        {...frmBnkAmtVal}
                        defaultValue={fromAmt === 0 || fromAmt ? Number(fromAmt).toFixed(2) : '' }
                        // 7228 - Add Transaction Amount Field (modified)
                        type={bankFromAmtChk ? "number": "text"}                      
                        onChange={evt=>this.onChangeAmountEmpty(evt, '', 'fromAmt')}
                        onFocus={evt=>this.onFocusAmountEmpty(evt, '', 'bankFromAmtChk')}
                        // end
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>
                          )
                        }}
                    />
                </Grid>
                {checkTaxBnkTransfAccnt && 
                  <Grid item xs={2} style={{position:'relative', left:'29px', top:'3px'}}>
                    <TextField
                            margin="dense" id="name" label="Tax Code" type="text"
                            variant="outlined"
                            value={frmBnkTaxCode}
                            style={{width:'86%'}}
                            onChange={evt => this.handleChange(evt, 'frmBnkTaxCode')}
                        />
                  </Grid>
                }
          </Grid>
          {
              bankingTransferTo.map((toacnt, toInd)=>{
                  const {accountName, co, amount, taxCode} = toacnt;
                  const bnkAmtVal = bankToAmtChk && amount === 0 ? { value: '' } : bankToAmtChk && amount !== 0 ? { value: amount } : bankToAmtChk === '' && amount === '' ? { value: '' } : { value: Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) };// 7228 - Add Transaction Amount Field (modified amtobj)
                  const arrayChecBnkAccnt = listacct.some(n=>n.accountName.trim() === accountName.trim() && n.ledger === 'Tax');
                  const toCoClass = {labelStyle:{position:'relative', bottom: co ? '0px': '6px'},inputStyle: {height:'5rem'}};
                  return(
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                          <TextField
                                  value={accountName ? accountName : ''} onChange={evt =>this.onBnkTransferToAccount(evt, toInd, 'accountName')}
                                  variant="outlined" name='toAccount' InputProps={{ style: {height:'4.8rem' } }}
                                  select style={{marginTop:'14px', width:'65%'}} label={<span style={{position:'relative', bottom: accountName ? '0px' : '7px'}}>To Account</span>}
                          >
                                  <MenuItem value={""}>To Account</MenuItem>
                                  {
                                  listacct && listacct.length > 0 && listacct.map(v=>v.accountName)
                                  .sort((a,b) => (a.toLowerCase() > b.toLowerCase()) - (a.toLowerCase() < b.toLowerCase()))
                                  .map(acct =>
                                      <MenuItem value={acct}>{acct}</MenuItem>
                                  )
                              }
                          </TextField>
                        </Grid>
                        <Grid item xs={1} style={{position:'relative', left:'1%', top:'25px'}}>
                            {toInd !== 0 && <Tooltip title="Delete Ledger Item">
                              <Icon onClick={evt => this.deleteLedgerItem(evt, toInd)  } color="action" style={{ fontSize: '32px', cursor: 'pointer', fontWeight: 'bold' }} >delete_outline</Icon>
                            </Tooltip>}
                            {bankingTransferTo.length - 1 === toInd && <Tooltip title="Add Ledger Item">
                              <Icon onClick={event => this.addLedgerItem()  } color="action" style={{ fontSize: '32px', cursor: 'pointer', fontWeight: 'bold' }} >add_circle</Icon>
                            </Tooltip>}
                        </Grid>
                        <Grid item xs={4} style={{position:'relative', top:'15px'}}>
                          <Autocomplete
                              clientCoName={clientCoName} money={true} secondFlag={true}
                              onSelectChange={this.onToCoSelect} autoCompleteClass={toCoClass}
                              openCliDialog = {this.openCliTransferDialog}
                              coName={co} toInd={toInd} placeHoldCo="To Customer"
                            />
                        </Grid>
                        <Grid item xs={2} style={{position:'relative', left:'14px', top:'8px'}}>
                          <TextField
                                margin="dense" id="name" label="Amount"
                                variant="outlined" onBlur={evt =>this.onBnkTransferToAccount(evt, toInd, 'amount')}
                                defaultValue={amount === 0 || amount ? Number(amount).toFixed(2) : '' }
                                {...bnkAmtVal}
                                // 7228 - Add Transaction Amount Field (modified)
                                type={bankToAmtChk ? "number": "text"}                      
                                onChange={(e) => this.onChangeAmountEmpty(e, toInd, 'bankToAmtChk')}
                                onFocus={evt=>this.onFocusAmountEmpty(evt, toInd, 'bankToAmtChk')}
                                // end
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>
                                  )
                                }}
                            />
                        </Grid>
                        {arrayChecBnkAccnt && 
                          <Grid item xs={2} style={{position:'relative', left:'29px', top:'8px'}}>
                            <TextField
                                    margin="dense" id="name" label="Tax Code" type="text"
                                    variant="outlined" value={taxCode} style={{width:'86%'}}
                                    onChange={evt => this.onBnkTransferToAccount(evt, toInd, 'taxCode')}
                                />
                          </Grid>
                        }
                  </Grid>
                  )
              })
          }
      </>
    )
  }

  nonBankingTransferJSX = () => {
    const {
      fromAccntNonBnking, clientCoName, listacct: nonBnkListAcct, nonBankingTransferTo, fromNonBnkAmt, fromNonBankCo, nonbankFromAmtChk, nonbankToAmtChk,
      frmNonBnkTaxCode
  } = this.state;
  const frmNonBnkAmtVal = nonbankFromAmtChk && fromNonBnkAmt === 0 ? { value: '' } : nonbankFromAmtChk && fromNonBnkAmt !== 0 ? { value: fromNonBnkAmt } : { value: Number(fromNonBnkAmt).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) };// 7228 - Add Transaction Amount Field (modified amtobj)
  const checkTaxAccnt = nonBnkListAcct.some(n=>n.accountName.trim() === fromAccntNonBnking.trim() && n.ledger === 'Tax');
  const fromCustomerAutoClass = {labelStyle:{position:'relative', bottom: fromNonBankCo ? '0px' : '6px'},inputStyle: {height:'5rem'}};
    return(
      <>
          <Grid container spacing={3}>
              <Grid item xs={checkTaxAccnt ? 3: 4} style={{position:'relative', top:'2px'}}>
                    <TextField
                          value={fromAccntNonBnking ? fromAccntNonBnking : ''} onChange={evt =>this.handleChange(evt,'fromAccntNonBnking')}
                          variant="outlined" select label={<span style={{position:'relative', bottom: fromAccntNonBnking ? '0px' : '7px'}}>Account</span>}
                          style={{width:'65%', marginTop:'10px'}} InputProps={{ style: {height:'4.8rem' } }}
                    >
                          <MenuItem value={""}>Select Account</MenuItem>
                          {
                          nonBnkListAcct && nonBnkListAcct.length > 0 && nonBnkListAcct.map(v=>v.accountName)
                          .sort((anon,bnon) => (anon.toLowerCase() > bnon.toLowerCase()) - (anon.toLowerCase() < bnon.toLowerCase()))
                          .map(nonbnkacct => <MenuItem value={nonbnkacct}>{nonbnkacct}</MenuItem>)
                      }
                  </TextField>
                </Grid>
                <Grid item xs={4} style={{position:'relative', top:'10px'}}>
                    <Autocomplete
                      clientCoName={clientCoName} money={true} autoCompleteClass={fromCustomerAutoClass}
                      onSelectChange={this.onFromBnkTransferCoSelect} placeHoldCo="From Customer"
                      openCliDialog = {this.openCliTransferDialog} coName={fromNonBankCo}
                    />
                </Grid>
                <Grid item xs={2} style={{position:'relative', left:'14px'}}>
                  <TextField
                        margin="dense" id="name" label="Amount"
                        variant="outlined" onBlur={evt =>this.handleChange(evt, 'fromNonBnkAmt')}
                        defaultValue={fromNonBnkAmt === 0 || fromNonBnkAmt ? Number(fromNonBnkAmt).toFixed(2) : '' }
                        {...frmNonBnkAmtVal}
                        // 7228 - Add Transaction Amount Field (modified)
                        type={nonbankFromAmtChk ? "number": "text"}                      
                        onChange={evt=>this.onChangeAmountEmpty(evt, '', 'fromNonBnkAmt')}
                        onFocus={evt=>this.onFocusAmountEmpty(evt, '', 'nonbankFromAmtChk')}
                        // end
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>
                          )
                        }}
                    />
                </Grid>
                {checkTaxAccnt && <Grid item xs={2} style={{position:'relative', left:'32px'}}>
                <TextField
                        margin="dense" id="name" label="Tax Code" type="text"
                        variant="outlined"
                        value={frmNonBnkTaxCode}
                        fullWidth
                        onChange={evt => this.handleChange(evt, 'frmNonBnkTaxCode')}
                    />
                </Grid>}
          </Grid>
          {
              nonBankingTransferTo.map((nonBnkAcnt, nonBnkToInd)=>{
                  const {co, amount, taxCode} = nonBnkAcnt;
                  const nbnkAmtVal = nonbankToAmtChk && amount === 0 ? { value: '' } : nonbankToAmtChk && amount !== 0 ? { value: amount } : nonbankToAmtChk === '' && amount === '' ? { value: '' } : { value: Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) };// 7228 - Add Transaction Amount Field (modified amtobj)
                  const toCustomerAutoClass = {labelStyle:{position:'relative', bottom: co ? '0px' : '6px'},inputStyle: {height:'5rem'}};
                  return(
                    <Grid container spacing={3} style={{position:'relative', top:'5px', marginTop:'4px'}}>
                      <Grid item xs={checkTaxAccnt ? 3: 4}></Grid>
                        <Grid item xs={4} style={{position:'relative', top:'8px'}}>
                            <Autocomplete
                              clientCoName={clientCoName} money={true}
                              onSelectChange={this.onToCoSelect}
                              openCliDialog = {this.openCliTransferDialog}
                              coName={co} toInd={nonBnkToInd} placeHoldCo="To Customer" autoCompleteClass={toCustomerAutoClass}
                            />
                        </Grid>
                        <Grid item xs={2} style={{position:'relative', left:'14px'}}>
                          <TextField
                                margin="dense" id="name" label="Amount"
                                variant="outlined" onBlur={evt =>this.onBnkTransferToAccount(evt, nonBnkToInd, 'amount')}
                                defaultValue={amount === 0 || amount ? Number(amount).toFixed(2) : '' }
                                {...nbnkAmtVal}
                                // 7228 - Add Transaction Amount Field (modified)
                                type={nonbankToAmtChk ? "number": "text"}                      
                                onChange={evt=>this.onChangeAmountEmpty(evt, nonBnkToInd, 'nonbankToAmtChk')}
                                onFocus={evt=>this.onFocusAmountEmpty(evt, nonBnkToInd, 'nonbankToAmtChk')}
                                // end
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>
                                  )
                                }}
                            />
                        </Grid>
                        {checkTaxAccnt &&
                        <Grid item xs={2} style={{position:'relative', left:'32px'}}>
                          <TextField
                              margin="dense" id="name" label="Tax Code" type="text"
                              variant="outlined" value={taxCode} fullWidth
                              onChange={evt => this.onBnkTransferToAccount(evt, nonBnkToInd, 'taxCode')}
                          />
                        </Grid>}
                        <Grid xs={1} item  style={{position:'relative', top:'13px', left:'42px'}}>
                          {nonBankingTransferTo.length - 1 === nonBnkToInd &&<Tooltip title="Add Ledger Item">
                            <Icon color="action" onClick={event => this.addLedgerItem()  }  style={{ fontWeight: 'bold', fontSize: '35px', cursor: 'pointer'}} >add_circle</Icon>
                          </Tooltip>}
                          {nonBnkToInd !== 0 && <Tooltip title="Delete Ledger Item">
                            <Icon onClick={evt => this.deleteLedgerItem(evt, nonBnkToInd)  } color="action" style={{ fontSize: '35px', cursor: 'pointer', fontWeight: 'bold' }} >delete_outline</Icon>
                          </Tooltip>}
                        </Grid>
                  </Grid>
                  )
              })
          }
      </>
    )
  }

  nonBnkTransferEnableButton = () => {
    const {nonBankingTransferTo, bnkDetails, fromAccntNonBnking, fromNonBankCo, fromNonBnkAmt, transactionDate, nonBnkTransfer} = this.state;
      const nonBnkTransferToBool = nonBankingTransferTo.every(p=> p.co.trim() && p.amount > 0 && p.amount !== 0);
      const fromNonBnkAccntBool =  nonBnkTransfer && fromAccntNonBnking.trim() && fromNonBankCo.trim() && bnkDetails ? true : false;
      let nonBnkTransferBool = false;
      let tmpNonBnkFromAmt = Math.abs(fromNonBnkAmt);
      if(fromNonBnkAccntBool && nonBnkTransferToBool && transactionDate) {
        let reduceNonBnkVal = nonBankingTransferTo.reduce((a, b) => a + (Number(Math.abs(b['amount']).toFixed(2)) || 0), 0);
        reduceNonBnkVal = Number(reduceNonBnkVal.toFixed(2));
        const nonBnkPositive = this.checkAmountPositive();
        nonBnkTransferBool = nonBnkPositive && (reduceNonBnkVal === tmpNonBnkFromAmt) ? true : false;
      }
      return nonBnkTransferBool;
  }

  bnkTransferEnableButton = () => {
      const {bankingTransferTo, bnkDetails, fromAccntSel, fromCo, fromAmt, transactionDate, bnkTransfer} = this.state;
      const transferToBool = bankingTransferTo.every(p=> p.accountName.trim() && p.co.trim() && p.amount > 0 && p.amount !== 0);
      const fromAccntBool =  bnkTransfer && fromAccntSel.trim() && fromCo.trim() && bnkDetails ? true : false;
      let bnkTransferBool = false;
      let tmpFromAmt = Math.abs(fromAmt);
      if(fromAccntBool && transferToBool && transactionDate) {
        let reduceVal = bankingTransferTo.reduce((a, b) => a + (Number(Math.abs(b['amount']).toFixed(2)) || 0), 0);
        reduceVal = Number(reduceVal.toFixed(2));
        const bnkPositive = this.checkAmountPositive();
        bnkTransferBool = bnkPositive && (reduceVal === tmpFromAmt) ? true : false;
      }
      return bnkTransferBool;
  }

  checkAmountPositive = () => {
    const {bankingTransferTo, nonBankingTransferTo, bnkTransfer, fromAmt, fromNonBnkAmt} = this.state;
    let amtPositive = false;
    if(bnkTransfer) {
      amtPositive = fromAmt >= 0 && bankingTransferTo.every(pbnk=> pbnk.amount >= 0);
    } else {
      amtPositive = fromNonBnkAmt >= 0 && nonBankingTransferTo.every(pnbnk=> pnbnk.amount >= 0);
    }
    return amtPositive;
  }

  snackbarTransferhandleClientClose = () => {
    this.setState({clientTransfSnack: false});
  }

  // story 7228 - Add Transaction Amount Field
  onFocusAmountEmpty = (evt, index, key ) => {
    const {amount, fromAmt, fromNonBnkAmt, bankingTransferTo, nonBankingTransferTo} = this.state;
    let amountValue = Number(amount) || 0.00;
    let fromAmountValue = Number(fromAmt) || 0.00;
    let fromNonBankAmountValue = Number(fromNonBnkAmt) || 0.00;

    if (amountValue && parseFloat(amountValue) % 1 === 0) amountValue = parseInt(amountValue, 10).toString();
    if (fromAmountValue && parseFloat(fromAmountValue) % 1 === 0) fromAmountValue = parseInt(fromAmountValue, 10).toString();
    if (fromNonBankAmountValue && parseFloat(fromNonBankAmountValue) % 1 === 0) fromNonBankAmountValue = parseInt(fromNonBankAmountValue, 10).toString();
    
    let updatedBankingTransferTo = [...bankingTransferTo];
    let updatedNonBankingTransferTo = [...nonBankingTransferTo];
 
    if(key === "bankToAmtChk"){
      updatedBankingTransferTo = bankingTransferTo.map((item, i) => {
        let rawValue = String(item.amount).replace(/,/g, "");
        let num = Number(rawValue);
        
        return {
            ...item,
            amount: i === index 
                ? (num === 0 ? "" : (num % 1 === 0 ? Number(num).toFixed(0) : Number(num))) // Clear if 0
                : (key === "bankToAmtChk" ? Number(num).toFixed(2) : Number(num).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) // Format others
        };
      });
    }

    if(key === "nonbankToAmtChk"){
      updatedNonBankingTransferTo = nonBankingTransferTo.map((item, i) => {
        let rawValue = String(item.amount).replace(/,/g, "");
        let num = Number(rawValue); 

        return {
            ...item,
            amount: i === index 
                ? (num === 0 ? "" : (num % 1 === 0 ? Number(num).toFixed(0) : Number(num))) // Clear if 0
                : (key === "nonbankToAmtChk" ? Number(num).toFixed(2) : Number(num).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) // Format others
        };
      });
    }

    this.setState({
        [key]: true, 
        amount: amountValue, 
        fromAmt: fromAmountValue, 
        fromNonBnkAmt: fromNonBankAmountValue, 
        bankingTransferTo: updatedBankingTransferTo, 
        nonBankingTransferTo: updatedNonBankingTransferTo
    });
  } 

  onChangeAmountEmpty = (evt, index, chk) => {
    let value = evt.target.value;
    const { bankingTransferTo, nonBankingTransferTo } = this.state;
    // Allow empty input (fully clears the field)
    if (value === "") {
        if (chk === 'bankToAmtChk') bankingTransferTo[index]['amount'] = value;
        if (chk === 'nonbankToAmtChk') nonBankingTransferTo[index]['amount'] = value;
        this.setState({ [chk]: value, bankingTransferTo, nonBankingTransferTo });
        return;
    }

    // Allow only valid numeric input (including negative and decimal)
    if (/^-?\d*\.?\d*$/.test(value)) {
        if (chk === 'bankToAmtChk') bankingTransferTo[index]['amount'] = value;
        if (chk === 'nonbankToAmtChk') nonBankingTransferTo[index]['amount'] = value;

        this.setState({ [chk]: value, bankingTransferTo, nonBankingTransferTo });
    }
  };
  // end

    render() {
        const { classes, openDialogTransfer } = this.props;
        const {
            transactionDate, bnkDetails,  bnkTransfer, nonBnkTransfer, clientTransfSnack, clientTransfSnckmessage, disableTransfer,
            openDialogTransferClients, chkAddTransferClient, compTransferName, compTransferFein, compTransferStatus, compTransferId
        } = this.state;
        let enableTransferSave = false;
        let avoidDupTransfer = disableTransfer ? {...disableHtmlElement} : {};
        if(chkAddTransferClient === 'add' && compTransferId.trim() && compTransferName.trim()) {
          if(compTransferStatus) {
              enableTransferSave = true;
          }
        }
        const enableBnkTransfer = this.bnkTransferEnableButton();
        const enableNonBnkTransfer = this.nonBnkTransferEnableButton();
        const chkAmount = this.checkAmountPositive();
        return (
          <>
            <Dialog
              open={openDialogTransfer}
              onClose={this.handleClose}
              disableBackdropClick
              disableEscapeKeyDown
              fullWidth  maxWidth="lg"
              scroll='body'
              classes={{
                paperFullWidth: classes.paperFullWidth
              }} 
            >
            <DialogTitle component="div" className="p-0">
            <AppBar style={{ color: 'white', backgroundColor:  platformHrThemeColor }} position="static" elevation={1}>
                <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16" style={{ overflowX: "scroll", overflowY: "hidden" }}>
                <div className="flex flex-1">
                Transfer Money
                </div>
                <IconButton color="inherit" ><Icon onClick={this.handleClose}>close</Icon></IconButton>
                </Toolbar>
            </AppBar>
            </DialogTitle>
            <DialogContent className="p-16 sm:p-24" style={{overflowY:'visible'}}>
              <div className="mb-16 sm:mb-8 flex">
              <Grid container spacing={3}>
              {!chkAmount && <Grid item xs={12} style={{color:'red', textAlign:'center', marginBottom:'14px'}}>Amount should be positive!</Grid>}
              <Grid item xs={12} sm={6}>
                  <Radio
                      checked={bnkTransfer === 'bnkTransfer'}
                      value={bnkTransfer}
                      onChange={event => this.handleBankingTransfer(event, "bnkTransfer")}
                  /> Banking Transfer
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Radio
                      checked={nonBnkTransfer === 'nonBnkTransfer'}
                      value={nonBnkTransfer}
                      onChange={event => this.handleBankingTransfer(event, "nonBnkTransfer")}
                  /> Non-Banking Transfer
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                    <TextField
                        id="date" label="Transaction Date"
                        type="date" value={moment(transactionDate).format('YYYY-MM-DD')}
                        variant="outlined" onChange={evt =>this.handleChange(evt, 'transactionDate')}
                        InputLabelProps={{ shrink: true}}
                      />
                </Grid>
                <Grid item xs={7} style={{position:'relative', left:'10px', bottom:'6px'}}>
                      <TextField
                          margin="dense" variant="outlined" id="bnkDetails"
                          label="Details" type="text"
                          InputProps={{ style:{ height: '54px', width:'72%'}}}
                          value={bnkDetails}
                          fullWidth
                          onChange={evt => this.handleChange(evt, 'bnkDetails')}
                      />
                </Grid>
              </Grid>
              {bnkTransfer && this.bankingTransferJSX()}
              {nonBnkTransfer && this.nonBankingTransferJSX()}
              </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button 
                autoFocus  onClick={this.handleClose} 
                style={{color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize'}}
                >
                Cancel
              </Button>
              <Button 
                onClick={this.transferLedgerTransactions} 
                style={enableBnkTransfer || enableNonBnkTransfer ? {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize', ...avoidDupTransfer}:
                {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize', ...disableHtmlElement}}
                >
                {disableTransfer ? 'Transfering...': 'Transfer'}
              </Button>
            </DialogActions>
            </Dialog>
            <div>
              {clientTransfSnack && snackBarComponent({
                      clientSnack: clientTransfSnack, duration: 400000, anchorigin: { vertical: 'top', horizontal: 'center'}, snackmessage: clientTransfSnckmessage,
                      snackbarhandleClose: this.snackbarTransferhandleClientClose
                  })
              }
              </div>
            {
                  openDialogTransferClients && 
                  <TransferMoneyClientDialog 
                      openDialogClients={openDialogTransferClients}
                      handleClose = {this.handleTransferClientClose}
                      handleChange = {this.handleChange} classes={classes}
                      saveClientData = {this.saveClientTransferData}
                      compId={compTransferId} compName={compTransferName} compFein={compTransferFein}
                      compStatus={compTransferStatus} chkAddClient={chkAddTransferClient} enableSave={enableTransferSave}
                      
                  />
            }
        </>
        )
    }
}

export default withStyles(useStyles)(TransferMoney);